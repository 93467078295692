@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed|Open+Sans');


.App {
  text-align: center;
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* override browser default */
html,
body {
  margin: 0;
  padding: 0;
}

/* use viewport-relative units to cover page fully */
body {
  height: 100vh;
  font-family: 'Open Sans', 'Droid Sans', Arial, sans-serif;
}

.horizontal::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}


::-webkit-scrollbar-thumb {
  background-color: rgba(60, 60, 60, .5);
  border-radius: 20px;
  border: 2px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* include border and padding in element width and height */
* {
  box-sizing: border-box;
}

/* full-sized  container that fills up the page */
div {
  /* height: 100%; */
  /* width: 100%; */

  /* example padding, font-size, background, etc */
  /* padding: 10px; */
  /* font-size: 20px; */
  /* background-color: lightskyblue; */
}

h1,h2 {
  font-family: 'Barlow Condensed', 'Roboto', 'Helvetica';
}

h1 {

  font-size: 2.5em;
}

p {
  font-size: 14px;
}